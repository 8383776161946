/* eslint-disable camelcase */
import { createReducer } from "reduxsauce";
import { ConfigurationTypes as Types } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.configuration;

export const getConfigurationFilesFailure = state => ({
  ...state,
});
export const getConfigurationFilesRequest = state => ({
  ...state,
});
export const getConfigurationFilesSuccess = (state, action) => {
  return {
    ...state,
    files: action.files.files,
    connections_count: action.files.connections_count,
  };
};

export const getDownloadFormatFileMappingFailure = state => ({
  ...state,
});
export const getDownloadFormatFileMappingRequest = state => ({
  ...state,
});
export const getDownloadFormatFileMappingSuccess = (
  state,
  action,
) => {
  return {
    ...state,
    download_format_mapping: action.mapping,
  };
};

export const saveDownloadFormatFileMappingFailure = state => ({
  ...state,
  isMappingUpdateRunning: false,
});
export const saveDownloadFormatFileMappingRequest = state => ({
  ...state,
  isMappingUpdateRunning: true,
});
export const saveDownloadFormatFileMappingSuccess = state => ({
  ...state,
  isMappingUpdateRunning: false,
});

export const getMappedColumnsFailure = state => ({
  ...state,
});
export const getMappedColumnsRequest = state => ({
  ...state,
});
export const getMappedColumnsSuccess = (state, action) => {
  return {
    ...state,
    mapped_columns: action.columns,
  };
};

export const getLeadsParametersFailure = state => ({
  ...state,
});
export const getLeadsParametersRequest = state => ({
  ...state,
});
export const getLeadsParametersSuccess = (state, action) => {
  const transformedData = action.parameters?.map(item => ({
    label: item.name,
    value: item.name,
    values: item.values,
    dropdownValues: item.dropdown_values.map(value => ({
      value,
      label: value,
    })),
  }));
  return {
    ...state,
    leadsParameters: transformedData,
  };
};

export const getLeadsCategoriesFailure = state => ({
  ...state,
});
export const getLeadsCategoriesRequest = state => ({
  ...state,
});
export const getLeadsCategoriesSuccess = (state, action) => {
  return {
    ...state,
    leadsCategories: action.leadsCategories,
  };
};

export const saveLeadsCategoriesFailure = state => ({
  ...state,
  isLeadsCategoriesSaving: false,
});
export const saveLeadsCategoriesRequest = state => ({
  ...state,
  isLeadsCategoriesSaving: true,
});
export const saveLeadsCategoriesSuccess = state => ({
  ...state,
  isLeadsCategoriesSaving: false,
});

export const getCrmListFailure = state => ({
  ...state,
});
export const getCrmListRequest = state => ({
  ...state,
});
export const getCrmListSuccess = (state, action) => {
  return {
    ...state,
    crmList: action.crmList,
  };
};

export const getSelectedCrmFailure = state => ({
  ...state,
});
export const getSelectedCrmRequest = state => ({
  ...state,
});
export const getSelectedCrmSuccess = (state, action) => {
  return {
    ...state,
    savedCrm: action.selectedCrm,
  };
};

export const saveCrmFailure = state => ({
  ...state,
  isCrmSaving: false,
});
export const saveCrmRequest = state => ({
  ...state,
  isCrmSaving: true,
});
export const saveCrmSuccess = state => {
  return {
    ...state,
    isCrmSaving: false,
  };
};

export const addCrmFailure = state => ({
  ...state,
});
export const addCrmRequest = state => ({
  ...state,
});
export const addCrmSuccess = state => ({
  ...state,
});

export const HANDLERS = {
  [Types.GET_CONFIGURATION_FILES_FAILURE]:
    getConfigurationFilesFailure,
  [Types.GET_CONFIGURATION_FILES_REQUEST]:
    getConfigurationFilesRequest,
  [Types.GET_CONFIGURATION_FILES_SUCCESS]:
    getConfigurationFilesSuccess,

  [Types.GET_DOWNLOAD_FORMAT_FILE_MAPPING_FAILURE]:
    getDownloadFormatFileMappingFailure,
  [Types.GET_DOWNLOAD_FORMAT_FILE_MAPPING_REQUEST]:
    getDownloadFormatFileMappingRequest,
  [Types.GET_DOWNLOAD_FORMAT_FILE_MAPPING_SUCCESS]:
    getDownloadFormatFileMappingSuccess,

  [Types.GET_MAPPED_COLUMNS_FAILURE]: getMappedColumnsFailure,
  [Types.GET_MAPPED_COLUMNS_REQUEST]: getMappedColumnsRequest,
  [Types.GET_MAPPED_COLUMNS_SUCCESS]: getMappedColumnsSuccess,

  [Types.SAVE_DOWNLOAD_FORMAT_FILE_MAPPING_FAILURE]:
    saveDownloadFormatFileMappingFailure,
  [Types.SAVE_DOWNLOAD_FORMAT_FILE_MAPPING_REQUEST]:
    saveDownloadFormatFileMappingRequest,
  [Types.SAVE_DOWNLOAD_FORMAT_FILE_MAPPING_SUCCESS]:
    saveDownloadFormatFileMappingSuccess,

  [Types.GET_LEADS_PARAMETERS_FAILURE]: getLeadsParametersFailure,
  [Types.GET_LEADS_PARAMETERS_REQUEST]: getLeadsParametersRequest,
  [Types.GET_LEADS_PARAMETERS_SUCCESS]: getLeadsParametersSuccess,

  [Types.GET_LEADS_CATEGORIES_FAILURE]: getLeadsCategoriesFailure,
  [Types.GET_LEADS_CATEGORIES_REQUEST]: getLeadsCategoriesRequest,
  [Types.GET_LEADS_CATEGORIES_SUCCESS]: getLeadsCategoriesSuccess,

  [Types.SAVE_LEADS_CATEGORIES_FAILURE]: saveLeadsCategoriesFailure,
  [Types.SAVE_LEADS_CATEGORIES_REQUEST]: saveLeadsCategoriesRequest,
  [Types.SAVE_LEADS_CATEGORIES_SUCCESS]: saveLeadsCategoriesSuccess,

  [Types.GET_CRM_LIST_FAILURE]: getCrmListFailure,
  [Types.GET_CRM_LIST_REQUEST]: getCrmListRequest,
  [Types.GET_CRM_LIST_SUCCESS]: getCrmListSuccess,

  [Types.GET_SELECTED_CRM_FAILURE]: getSelectedCrmFailure,
  [Types.GET_SELECTED_CRM_REQUEST]: getSelectedCrmRequest,
  [Types.GET_SELECTED_CRM_SUCCESS]: getSelectedCrmSuccess,

  [Types.SAVE_CRM_FAILURE]: saveCrmFailure,
  [Types.SAVE_CRM_REQUEST]: saveCrmRequest,
  [Types.SAVE_CRM_SUCCESS]: saveCrmSuccess,

  [Types.ADD_CRM_FAILURE]: addCrmFailure,
  [Types.ADD_CRM_REQUEST]: addCrmRequest,
  [Types.ADD_CRM_SUCCESS]: addCrmSuccess,
};

export default createReducer(INITIAL_STATE, HANDLERS);
