/* eslint-disable no-unused-expressions */
import {
  call,
  put,
  takeEvery,
  takeLatest,
  select,
} from "redux-saga/effects";
import { ConnectionsCreators, ConnectionsTypes } from "../actions";
import {
  getConnections,
  getConnectionCountries,
  getEngagementType,
  getNextSteps,
  getLeadsCategory,
  getAssignedRegions,
  getConnectionCitizenship,
  getConnectionWebinar,
  getConnectionGraudationYear,
  getConnectionDetails,
  downloadConnections,
  getEngagementData,
  getConnectionSources,
  getConnectionActivities,
  getConnectionStatuses,
  hideCountsConnections,
  getConnectionSchoolStates,
  getConnectionSchoolNames,
  getConnectionSchoolCity,
  getConnectionAcademicInterest,
  getConnectionCourseInterest,
  getConnectionEventTitle,
} from "src/web-services";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";

const authTokenSelector = state => state.user.authToken;

export function* requestGetEngagementData(action) {
  try {
    const { params, resolve } = action;
    const authToken = yield select(authTokenSelector);
    const connectionsSelector = state => state.connections;

    const {
      selectedActivities: currentConnections,
      activitiesParams: currentParams,
      selectedConnectionDetails: currentSelectedConnectionDetails,
    } = yield select(connectionsSelector);

    const shouldLoadMore =
      currentConnections.engagements.length > 0 &&
      isEqual(omit(currentParams, ["page_no"]), params) &&
      currentSelectedConnectionDetails.id === action.id;

    const page = shouldLoadMore ? currentParams.page_no + 1 : 1;
    const requestParams = { ...params };
    requestParams.page_no = page;
    const response = yield call(
      getEngagementData,
      authToken,
      action.id,
      requestParams,
    );
    resolve && resolve();
    yield put(
      ConnectionsCreators.getEngagementDataSuccess(
        response.data,
        requestParams,
        action.id,
      ),
    );
  } catch (error) {
    const { reject } = action;
    reject && reject(error);
    yield put(ConnectionsCreators.getEngagementDataFailure(error));
  }
}

export function* requestGetConnections(action) {
  try {
    const { params, resolve } = action;
    const connectionsSelector = state => state.connections;
    const authToken = yield select(authTokenSelector);
    const { data: currentConnections, params: currentParams } =
      yield select(connectionsSelector);
    const shouldLoadMore =
      currentConnections.length > 0 &&
      isEqual(omit(currentParams, ["page_no"]), params);
    if (!shouldLoadMore)
      yield put(ConnectionsCreators.clearConnections());

    const page = shouldLoadMore ? currentParams.page_no + 1 : 1;
    const requestParams = { ...params };
    requestParams["page_no"] = page;
    const response = yield call(
      getConnections,
      authToken,
      requestParams,
    );
    resolve && resolve();
    yield put(
      ConnectionsCreators.getConnectionsSuccess(
        response.data,
        requestParams,
      ),
    );
  } catch (error) {
    const { reject } = action;
    reject && reject(error);
    yield put(ConnectionsCreators.getConnectionsFailure());
  }
}

export function* requestGetConnectionCountries(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getConnectionCountries,
      authToken,
      params,
    );
    yield put(
      ConnectionsCreators.getConnectionCountriesSuccess(
        response.data.country,
      ),
    );
  } catch (error) {
    yield put(ConnectionsCreators.getConnectionCountriesFailure());
  }
}

export function* requestGetEngagementType() {
  try {
    const authToken = yield select(authTokenSelector);
    const response = yield call(getEngagementType, authToken);
    yield put(
      ConnectionsCreators.getEngagementTypeSuccess(
        response.data.engagement_type,
      ),
    );
  } catch (error) {
    yield put(ConnectionsCreators.getEngagementTypeFailure());
  }
}

export function* requestGetNextSteps() {
  try {
    const authToken = yield select(authTokenSelector);
    const response = yield call(getNextSteps, authToken);
    yield put(
      ConnectionsCreators.getNextStepsSuccess(
        response.data.recommended_steps,
      ),
    );
  } catch (error) {
    yield put(ConnectionsCreators.getNextStepsFailure());
  }
}

export function* requestGetLeadsCategory() {
  try {
    const authToken = yield select(authTokenSelector);
    const response = yield call(getLeadsCategory, authToken);
    yield put(
      ConnectionsCreators.getLeadsCategorySuccess(
        response.data.lead_categories,
      ),
    );
  } catch (error) {
    yield put(ConnectionsCreators.getLeadsCategoryFailure());
  }
}

export function* requestGetAssignedRegions() {
  try {
    const authToken = yield select(authTokenSelector);
    const response = yield call(getAssignedRegions, authToken);
    yield put(
      ConnectionsCreators.getAssignedRegionsSuccess(response.data),
    );
  } catch (error) {
    yield put(ConnectionsCreators.getAssignedRegionsFailure());
  }
}

export function* requestGetConnectionCitizenship(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getConnectionCitizenship,
      authToken,
      params,
    );
    yield put(
      ConnectionsCreators.getConnectionCitizenshipSuccess(
        response.data.citizenships,
      ),
    );
  } catch (error) {
    yield put(ConnectionsCreators.getConnectionCitizenshipFailure());
  }
}

export function* requestGetConnectionWebinar(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getConnectionWebinar,
      authToken,
      params,
    );
    yield put(
      ConnectionsCreators.getConnectionWebinarsSuccess(
        response.data.webinars,
      ),
    );
  } catch (error) {
    yield put(ConnectionsCreators.getConnectionWebinarsFailure());
  }
}

export function* requestGetConnectionSources(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getConnectionSources,
      authToken,
      params,
    );
    yield put(
      ConnectionsCreators.getConnectionSourcesSuccess(
        response.data.source_types,
      ),
    );
  } catch (error) {
    yield put(ConnectionsCreators.getConnectionSourcesFailure());
  }
}

export function* requestGetConnectionActivities(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getConnectionActivities,
      authToken,
      params,
    );
    yield put(
      ConnectionsCreators.getConnectionActivitiesSuccess(
        response.data.activity_types,
      ),
    );
  } catch (error) {
    yield put(ConnectionsCreators.getConnectionActivitiesFailure());
  }
}

export function* requestGetConnectionStatuses(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getConnectionStatuses,
      authToken,
      params,
    );
    yield put(
      ConnectionsCreators.getConnectionStatusesSuccess(
        response.data.statuses,
      ),
    );
  } catch (error) {
    yield put(ConnectionsCreators.getConnectionStatusesFailure());
  }
}

export function* requestGetConnectionSchoolStates(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getConnectionSchoolStates,
      authToken,
      params,
    );
    yield put(
      ConnectionsCreators.getConnectionSchoolStatesSuccess(
        response.data.school_states,
      ),
    );
  } catch (error) {
    yield put(ConnectionsCreators.getConnectionSchoolStatesFailure());
  }
}

export function* requestGetConnectionSchoolNames(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getConnectionSchoolNames,
      authToken,
      params,
    );
    yield put(
      ConnectionsCreators.getConnectionSchoolNamesSuccess(
        response.data.school_names,
      ),
    );
  } catch (error) {
    yield put(ConnectionsCreators.getConnectionSchoolNamesFailure());
  }
}

export function* requestGetConnectionSchoolCity(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getConnectionSchoolCity,
      authToken,
      params,
    );
    yield put(
      ConnectionsCreators.getConnectionSchoolCitySuccess(
        response.data.school_city,
      ),
    );
  } catch (error) {
    yield put(ConnectionsCreators.getConnectionSchoolCityFailure());
  }
}

export function* requestGetConnectionCourseInterest(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getConnectionCourseInterest,
      authToken,
      params,
    );
    yield put(
      ConnectionsCreators.getConnectionCourseInterestSuccess(
        response.data.courses_interests,
      ),
    );
  } catch (error) {
    yield put(
      ConnectionsCreators.getConnectionCourseInterestFailure(),
    );
  }
}

export function* requestGetConnectionAcademicInterest(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getConnectionAcademicInterest,
      authToken,
      params,
    );
    yield put(
      ConnectionsCreators.getConnectionAcademicInterestSuccess(
        response.data.academic_interests,
      ),
    );
  } catch (error) {
    yield put(
      ConnectionsCreators.getConnectionAcademicInterestFailure(),
    );
  }
}

export function* requestGetConnectionEventTitle(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getConnectionEventTitle,
      authToken,
      params,
    );
    yield put(
      ConnectionsCreators.getConnectionEventTitleSuccess(
        response.data.event_titles,
      ),
    );
  } catch (error) {
    yield put(ConnectionsCreators.getConnectionEventTitleFailure());
  }
}

export function* requestGetConnectionGraudationYear(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getConnectionGraudationYear,
      authToken,
      params,
    );
    yield put(
      ConnectionsCreators.getConnectionGraduationYearSuccess(
        response.data.graduation_years,
      ),
    );
  } catch (error) {
    yield put(
      ConnectionsCreators.getConnectionGraduationYearFailure(),
    );
  }
}

export function* requestGetConnectionDetails(action) {
  try {
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getConnectionDetails,
      authToken,
      action.id,
    );

    yield put(
      ConnectionsCreators.getConnectionDetailsSuccess(
        response.data.connection,
      ),
    );
  } catch (error) {
    yield put(ConnectionsCreators.getConnectionDetailsFailure(error));
  }
}

export function* requestDownloadConnections(action) {
  try {
    const { params, resolve } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      downloadConnections,
      authToken,
      params,
    );
    resolve && resolve();
    yield put(
      ConnectionsCreators.downloadConnectionsSuccess(response.data),
    );
  } catch (error) {
    const { reject } = action;
    reject && reject(error);
    yield put(ConnectionsCreators.downloadConnectionsFailure());
  }
}

export function* requestHideCounts(action) {
  try {
    const { params, resolve } = action;
    const authToken = yield select(authTokenSelector);
    if (!authToken) {
      resolve && resolve();
      return;
    }
    const response = yield call(
      hideCountsConnections,
      authToken,
      params,
    );
    resolve && resolve();
    yield put(
      ConnectionsCreators.hideCountsSuccess(
        response.data.user_connection_count,
      ),
    );
  } catch (error) {
    const { reject } = action;
    reject && reject(error);
    yield put(ConnectionsCreators.hideCountsFailure());
  }
}

export function* watchConnectionsRequests() {
  yield takeLatest(
    ConnectionsTypes.GET_CONNECTIONS_REQUEST,
    requestGetConnections,
  );
  yield takeEvery(
    ConnectionsTypes.GET_CONNECTION_COUNTRIES_REQUEST,
    requestGetConnectionCountries,
  );
  yield takeEvery(
    ConnectionsTypes.GET_ENGAGEMENT_TYPE_REQUEST,
    requestGetEngagementType,
  );
  yield takeEvery(
    ConnectionsTypes.GET_NEXT_STEPS_REQUEST,
    requestGetNextSteps,
  );
  yield takeEvery(
    ConnectionsTypes.GET_LEADS_CATEGORY_REQUEST,
    requestGetLeadsCategory,
  );
  yield takeEvery(
    ConnectionsTypes.GET_ASSIGNED_REGIONS_REQUEST,
    requestGetAssignedRegions,
  );
  yield takeEvery(
    ConnectionsTypes.GET_CONNECTION_CITIZENSHIP_REQUEST,
    requestGetConnectionCitizenship,
  );
  yield takeEvery(
    ConnectionsTypes.GET_CONNECTION_WEBINARS_REQUEST,
    requestGetConnectionWebinar,
  );
  yield takeEvery(
    ConnectionsTypes.GET_CONNECTION_GRADUATION_YEAR_REQUEST,
    requestGetConnectionGraudationYear,
  );
  yield takeEvery(
    ConnectionsTypes.GET_CONNECTION_DETAILS_REQUEST,
    requestGetConnectionDetails,
  );
  yield takeEvery(
    ConnectionsTypes.DOWNLOAD_CONNECTIONS_REQUEST,
    requestDownloadConnections,
  );
  yield takeEvery(
    ConnectionsTypes.GET_ENGAGEMENT_DATA_REQUEST,
    requestGetEngagementData,
  );
  yield takeEvery(
    ConnectionsTypes.GET_CONNECTION_SOURCES_REQUEST,
    requestGetConnectionSources,
  );
  yield takeEvery(
    ConnectionsTypes.GET_CONNECTION_ACTIVITIES_REQUEST,
    requestGetConnectionActivities,
  );
  yield takeEvery(
    ConnectionsTypes.GET_CONNECTION_STATUSES_REQUEST,
    requestGetConnectionStatuses,
  );
  yield takeEvery(
    ConnectionsTypes.HIDE_COUNTS_REQUEST,
    requestHideCounts,
  );
  yield takeEvery(
    ConnectionsTypes.GET_CONNECTION_SCHOOL_STATES_REQUEST,
    requestGetConnectionSchoolStates,
  );
  yield takeEvery(
    ConnectionsTypes.GET_CONNECTION_SCHOOL_NAMES_REQUEST,
    requestGetConnectionSchoolNames,
  );
  yield takeEvery(
    ConnectionsTypes.GET_CONNECTION_SCHOOL_CITY_REQUEST,
    requestGetConnectionSchoolCity,
  );
  yield takeEvery(
    ConnectionsTypes.GET_CONNECTION_COURSE_INTEREST_REQUEST,
    requestGetConnectionCourseInterest,
  );
  yield takeEvery(
    ConnectionsTypes.GET_CONNECTION_ACADEMIC_INTEREST_REQUEST,
    requestGetConnectionAcademicInterest,
  );
  yield takeEvery(
    ConnectionsTypes.GET_CONNECTION_EVENT_TITLE_REQUEST,
    requestGetConnectionEventTitle,
  );
}
