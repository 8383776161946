import { API } from "../helpers";
import { SERVER_URL } from "../config";

export function getConnections(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/connections`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getConnectionCountries(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/connections/country`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getEngagementType(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/connections/engagement_type_filters`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getNextSteps(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/connections/recommended_steps_filters`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getLeadsCategory(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/connections/lead_categories_filters`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getAssignedRegions(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/connections/assigned_regions`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getConnectionCitizenship(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/connections/citizenships`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getConnectionWebinar(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/connections/webinar_category`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getConnectionSources(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/connections/source_type`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getConnectionActivities(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/connections/activity_type`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getConnectionStatuses(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/connections/status`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getConnectionSchoolStates(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/connections/school_state`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getConnectionSchoolNames(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/connections/school_name`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getConnectionSchoolCity(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/connections/school_city`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getConnectionCourseInterest(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/connections/courses_interest`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getConnectionAcademicInterest(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/connections/academic_interest`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getConnectionEventTitle(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/connections/event_titles`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getConnectionGraudationYear(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/connections/graduation_years`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getConnectionDetails(authToken, id) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/connections/${id}`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getEngagementData(authToken, id, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/connections/${id}/activities`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function downloadConnections(authToken, params) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/connections/download`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function hideCountsConnections(authToken, params) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/connections/update_listing_badge`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getConnectionDownloadStatus(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/connections/download_status`,
    headers: {
      authentication_token: authToken,
    },
  });
}
