import {
  all,
  call,
  put,
  takeEvery,
  select,
} from "redux-saga/effects";
import {
  getCountries,
  getTimezones,
  getRegions,
  getConnectionsRegions,
  getSchoolSizes,
  getMembers,
  getAdmins,
  getManagementTools,
  getPartner,
  getEventModificationReasons,
  getWebinar,
  getPaymentInfo,
  sendSlackNotification,
  getEventDeclineReasons,
} from "src/web-services";
import { MiscCreators, MiscTypes, PartnerCreators } from "../actions";

export function* requestGetAllData(action) {
  try {
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);

    const [
      countriesResponse,
      timezonesResponse,
      regionsResponse,
      schoolSizesResponse,
      partnerResponse,
      eventMoficationReasonsResponse,
    ] = yield all([
      call(getCountries),
      call(getTimezones),
      call(getRegions),
      call(getSchoolSizes),
      call(getPartner, authToken),
      call(getEventModificationReasons),
    ]);

    yield put(
      PartnerCreators.getPartnerSuccess(partnerResponse.data),
    );
    yield put(
      MiscCreators.getMiscDataSuccess(
        countriesResponse.data.countries,
        timezonesResponse.data.timezone,
        regionsResponse.data.regions,
        schoolSizesResponse.data.size,
        eventMoficationReasonsResponse.data.reschedule_reason,
      ),
    );
  } catch (error) {
    yield put(MiscCreators.getMiscDataFailure());
  }
}

export function* requestGetCountries(action) {
  try {
    const { params } = action;
    const response = yield call(getCountries, params);
    yield put(
      MiscCreators.getCountriesSuccess(response.data.countries),
    );
  } catch (error) {
    yield put(MiscCreators.getCountriesFailure());
  }
}

export function* requestGetEventDeclineReasons() {
  try {
    const response = yield call(getEventDeclineReasons);
    yield put(
      MiscCreators.getEventDeclineReasonsSuccess(response.data),
    );
  } catch (error) {
    yield put(MiscCreators.getEventDeclineReasonsFailure());
  }
}

export function* requestGetEventsCountries(action) {
  try {
    const stateSelector = state => state;
    const {
      partner: { data: partner },
    } = yield select(stateSelector);
    const params = {
      partner_id: partner.id,
      search_type: "event",
    };

    const response = yield call(getCountries, params);
    yield put(
      MiscCreators.getEventsCountriesSuccess(response.data.countries),
    );
  } catch (error) {
    yield put(MiscCreators.getEventsCountriesFailure());
  }
}

export function* requestGetTeamCountries(action) {
  try {
    const stateSelector = state => state;
    const {
      partner: { data: partner },
    } = yield select(stateSelector);
    const params = {
      partner_id: partner.id,
      search_type: "team",
    };

    const response = yield call(getCountries, params);
    yield put(
      MiscCreators.getTeamCountriesSuccess(response.data.countries),
    );
  } catch (error) {
    yield put(MiscCreators.getTeamCountriesFailure());
  }
}

export function* requestGetTimezones(action) {
  try {
    const response = yield call(getTimezones);
    yield put(
      MiscCreators.getTimezonesSuccess(response.data.timezone),
    );
  } catch (error) {
    yield put(MiscCreators.getTimezonesFailure());
  }
}

export function* requestGetRegions(action) {
  try {
    const response = yield call(getRegions);
    yield put(MiscCreators.getRegionsSuccess(response.data.regions));
  } catch (error) {
    yield put(MiscCreators.getRegionsFailure());
  }
}

export function* requestGetConnectionsRegions() {
  try {
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(getConnectionsRegions, authToken);
    yield put(
      MiscCreators.getConnectionsRegionsSuccess(
        response.data.regions,
      ),
    );
  } catch (error) {
    yield put(MiscCreators.getConnectionsRegionsFailure());
  }
}

export function* requestGetSchoolSizes(action) {
  try {
    const response = yield call(getSchoolSizes);
    yield put(MiscCreators.getSchoolSizesSuccess(response.data.size));
  } catch (error) {
    yield put(MiscCreators.getSchoolSizesFailure());
  }
}

export function* requestGetManagementTools(action) {
  try {
    const response = yield call(getManagementTools);
    yield put(
      MiscCreators.getManagementToolsSuccess(
        response.data.management_tools,
      ),
    );
  } catch (error) {
    yield put(MiscCreators.getManagementToolsFailure());
  }
}

export function* requestGetMembers(action) {
  try {
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(getMembers, authToken);
    yield put(MiscCreators.getMembersSuccess(response.data.members));
  } catch (error) {
    yield put(MiscCreators.getMembersFailure());
  }
}

export function* requestGetAdmins(action) {
  try {
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(getAdmins, authToken);
    yield put(MiscCreators.getAdminsSuccess(response.data.members));
  } catch (error) {
    yield put(MiscCreators.getAdminsFailure());
  }
}

export function* requestGetEventMoficationReasons(action) {
  try {
    const response = yield call(getEventModificationReasons);
    yield put(
      MiscCreators.getEventModificationReasonsSuccess(
        response.data.reschedule_reason,
      ),
    );
  } catch (error) {
    yield put(MiscCreators.getEventModificationReasonsFailure());
  }
}

export function* requestgetWebinar(action) {
  try {
    const response = yield call(getWebinar);
    yield put(MiscCreators.getWebinarSuccess(response.data));
  } catch (error) {
    yield put(MiscCreators.getWebinarFailure());
  }
}

export function* requestGetPaymentInfo(action) {
  try {
    const { id } = action;

    const response = yield call(getPaymentInfo, id);
    yield put(
      MiscCreators.getPaymentInfoSuccess(
        response.data.client_id,
        response.data.order_id,
      ),
    );
  } catch (error) {
    yield put(MiscCreators.getPaymentInfoFailure());
  }
}

export function* requestSendSlackNotification(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      sendSlackNotification,
      authToken,
      params,
    );

    yield put(
      MiscCreators.sendSlackNotificationSuccess(response.data),
    );
  } catch (error) {
    yield put(MiscCreators.sendSlackNotificationFailure());
  }
}

export function* watchMiscRequests() {
  yield takeEvery(MiscTypes.GET_MISC_DATA_REQUEST, requestGetAllData);
  yield takeEvery(
    MiscTypes.GET_EVENT_DECLINE_REASONS_REQUEST,
    requestGetEventDeclineReasons,
  );
  yield takeEvery(
    MiscTypes.GET_COUNTRIES_REQUEST,
    requestGetCountries,
  );
  yield takeEvery(
    MiscTypes.GET_EVENTS_COUNTRIES_REQUEST,
    requestGetEventsCountries,
  );
  yield takeEvery(
    MiscTypes.GET_TEAM_COUNTRIES_REQUEST,
    requestGetTeamCountries,
  );
  yield takeEvery(
    MiscTypes.GET_TIMEZONES_REQUEST,
    requestGetTimezones,
  );
  yield takeEvery(MiscTypes.GET_REGIONS_REQUEST, requestGetRegions);
  yield takeEvery(
    MiscTypes.GET_CONNECTIONS_REGIONS_REQUEST,
    requestGetConnectionsRegions,
  );
  yield takeEvery(
    MiscTypes.GET_SCHOOL_SIZES_REQUEST,
    requestGetSchoolSizes,
  );
  yield takeEvery(
    MiscTypes.GET_MANAGEMENT_TOOLS_REQUEST,
    requestGetManagementTools,
  );
  yield takeEvery(MiscTypes.GET_MEMBERS_REQUEST, requestGetMembers);
  yield takeEvery(MiscTypes.GET_ADMINS_REQUEST, requestGetAdmins);
  yield takeEvery(
    MiscTypes.GET_EVENT_MODIFICATION_REASONS_REQUEST,
    requestGetEventMoficationReasons,
  );
  yield takeEvery(
    MiscTypes.GET_PAYMENT_INFO_REQUEST,
    requestGetPaymentInfo,
  );
  yield takeEvery(
    MiscTypes.SEND_SLACK_NOTIFICATION_REQUEST,
    requestSendSlackNotification,
  );
}
