import { API } from "../helpers";
import { SERVER_URL } from "../config";
import qs from "qs";

export function getCountries(params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/countries?search_type=all`,
    params,
  });
}

export function getTimezones() {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/timezones`,
  });
}

export function getStates(params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/states`,
    params,
  });
}

export function getCities(params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/cities`,
    params,
  });
}

export function getRegions() {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/regions`,
  });
}

export function getConnectionsRegions(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/connections/all_regions`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getSchoolSizes() {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/school_sizes`,
  });
}

export function getManagementTools() {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/management_tools`,
  });
}

export function getMembers(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/teams/members_list`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getAdmins(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/users/admins_list`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getEventDeclineReasons() {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/reject_reasons`,
  });
}

export function getEventModificationReasons() {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/reschedule_reasons`,
  });
}

export function searchSchools(params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/schools`,
    params,
  });
}

export function searchUniversities(params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/universities`,
    params,
  });
}

export function getPartnerTimezone(authToken, id, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/users/${id}/partner_timezone`,
    headers: {
      authentication_token: authToken,
    },
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
  });
}

export function getWebinar(params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/webinars`,
    params,
  });
}

export function getPaymentInfo(id) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/payment/transactions/${id}`,
  });
}

export function sendSlackNotification(authToken, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/misc/notify_slack`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}
