import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getConfigurationFilesFailure: null,
  getConfigurationFilesRequest: null,
  getConfigurationFilesSuccess: ["files"],

  getDownloadFormatFileMappingFailure: null,
  getDownloadFormatFileMappingRequest: null,
  getDownloadFormatFileMappingSuccess: ["mapping"],

  saveDownloadFormatFileMappingFailure: null,
  saveDownloadFormatFileMappingRequest: ["mapping_data"],
  saveDownloadFormatFileMappingSuccess: null,

  getMappedColumnsFailure: null,
  getMappedColumnsRequest: null,
  getMappedColumnsSuccess: ["columns"],

  getLeadsParametersFailure: null,
  getLeadsParametersRequest: null,
  getLeadsParametersSuccess: ["parameters"],

  getLeadsCategoriesFailure: null,
  getLeadsCategoriesRequest: null,
  getLeadsCategoriesSuccess: ["leadsCategories"],

  saveLeadsCategoriesFailure: null,
  saveLeadsCategoriesRequest: ["data"],
  saveLeadsCategoriesSuccess: null,

  getCrmListFailure: null,
  getCrmListRequest: null,
  getCrmListSuccess: ["crmList"],

  getSelectedCrmFailure: null,
  getSelectedCrmRequest: null,
  getSelectedCrmSuccess: ["selectedCrm"],

  saveCrmFailure: null,
  saveCrmRequest: ["data"],
  saveCrmSuccess: null,

  addCrmFailure: null,
  addCrmRequest: ["name"],
  addCrmSuccess: null,
});

export const ConfigurationTypes = Types;
export const ConfigurationCreators = Creators;
