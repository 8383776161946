/* eslint-disable no-use-before-define */
import { call, put, takeEvery, select } from "redux-saga/effects";
import {
  checkSession,
  googleSSO,
  confirmaEmailId,
  registerUser,
  sendResetPasswordMail,
  requestDemo,
  resetPassword,
  setOnboarding,
  signIn,
  sso,
  ssoCialfo,
  updateUser,
  updateUserTimezone,
  updatePassword,
  acceptPrivacyPolicy,
  updateUserLocale,
  updateEmailSettings,
  getPartner,
  updateZoomDetails,
  getZoomAuthUrl,
  getSisenseUrl,
  getSisenseDashboards,
  getMetabaseUrl,
  getMetabaseDashboards,
} from "src/web-services";
import { Notification } from "src/helpers/notification";
import { SubmissionError } from "redux-form";
import each from "lodash/each";
import i18n from "src/i18n";
import Analytics from "src/helpers/analytics";
import { UserCreators, UserTypes, MiscCreators } from "../actions";

export function* watchUserRequests() {
  yield takeEvery(UserTypes.AUTHENTICATION_REQUEST, requestSignIn);
  yield takeEvery(UserTypes.GOOGLE_SSO_REQUEST, requestGoogleSSO);
  yield takeEvery(
    UserTypes.FINISH_ONBOARDING_REQUEST,
    requestFinishOnboarding,
  );
  yield takeEvery(UserTypes.SSO_REQUEST, requestSSO);
  yield takeEvery(UserTypes.SSO_CIALFO_REQUEST, requestSSOCialfo);
  yield takeEvery(
    UserTypes.CHECK_SESSION_REQUEST,
    requestCheckSession,
  );
  yield takeEvery(
    UserTypes.CONFIRM_EMAIL_ID_REQUEST,
    requestConfirmEmailId,
  );
  yield takeEvery(
    UserTypes.SEND_RESET_PASSWORD_MAIL_REQUEST,
    requestSendResetPasswordMail,
  );
  yield takeEvery(UserTypes.REQUEST_DEMO_REQUEST, requestDemoRequest);
  yield takeEvery(
    UserTypes.RESET_PASSWORD_REQUEST,
    requestResetPassword,
  );
  yield takeEvery(
    UserTypes.REGISTER_USER_REQUEST,
    requestRegisterUser,
  );
  yield takeEvery(UserTypes.UPDATE_USER_REQUEST, requestUpdateUser);
  yield takeEvery(
    UserTypes.UPDATE_USER_TIMEZONE_REQUEST,
    requestUpdateUserTimezone,
  );
  yield takeEvery(
    UserTypes.UPDATE_PASSWORD_REQUEST,
    requestUpdatePassword,
  );
  yield takeEvery(
    UserTypes.ACCEPT_PRIVACY_POLICY_REQUEST,
    requestAcceptPrivacyPolicy,
  );
  yield takeEvery(
    UserTypes.UPDATE_USER_LOCALE_REQUEST,
    requestUpdateUserLocale,
  );
  yield takeEvery(
    UserTypes.UPDATE_EMAIL_SETTINGS_REQUEST,
    requestUpdateEmailSettings,
  );
  yield takeEvery(
    UserTypes.GET_ZOOM_ACCOUNT_REQUEST,
    requestGetZoomAccount,
  );
  yield takeEvery(
    UserTypes.GET_ZOOM_AUTH_URL_REQUEST,
    requestGetZoomAuthUrl,
  );
  yield takeEvery(
    UserTypes.UPDATE_ZOOM_DETAILS_REQUEST,
    requestUpdateZoomDetails,
  );
  yield takeEvery(
    UserTypes.GET_SISENSE_URL_REQUEST,
    requestSisenseUrl,
  );
  yield takeEvery(
    UserTypes.GET_SISENSE_DASHBOARDS_REQUEST,
    requestSisenseDashboards,
  );
  yield takeEvery(
    UserTypes.GET_METABASE_URL_REQUEST,
    requestMetabaseUrl,
  );
  yield takeEvery(
    UserTypes.GET_METABASE_DASHBOARDS_REQUEST,
    requestMetabaseDashboards,
  );
}

const trackLoginFail = email =>
  Analytics.track("Login Fail", {
    "Login Type": "Email",
    Email: email,
  });

export function* requestSignIn(action) {
  try {
    const { params, resolve } = action;
    const response = yield call(signIn, params);
    resolve();
    yield put(UserCreators.authenticationSuccess(response.data));
    Analytics.track("Login", {
      "Login Type": "Email",
      "University Name": response.data.company.name,
      Email: response.data.email,
    });

    if (response.data.is_calender_expired)
      yield put(MiscCreators.showCalendarExpiryMessage());
  } catch (error) {
    const { reject, params } = action;

    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 404)
    ) {
      let errorMessages = {};
      trackLoginFail(params.email);
      each(error.response.data.error, (e, key) => {
        errorMessages[key] = e instanceof Array ? e : [e];
      });

      reject(new SubmissionError(errorMessages));
    } else {
      trackLoginFail(params.email);
      reject(error);
    }

    yield put(UserCreators.authenticationFailure());
  }
}

export function* requestGoogleSSO(action) {
  try {
    const { code, resolve } = action;
    const params = { code };
    const response = yield call(googleSSO, params);

    yield put(UserCreators.googleSSOSuccess(response.data));
    resolve();
  } catch (error) {
    const { reject } = action;
    reject(error);
    yield put(UserCreators.googleSSOFailure());
  }
}

export function* requestFinishOnboarding(action) {
  try {
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const userIdSelector = state => state.user.data.id;
    const id = yield select(userIdSelector);
    const params = { is_onboard: true };
    yield call(setOnboarding, authToken, id, params);
    yield put(UserCreators.finishOnboardingSuccess());
  } catch (error) {
    yield put(UserCreators.finishOnboardingFailure());
  }
}

export function* requestSSO(action) {
  try {
    const { params } = action;
    const response = yield call(sso, params);
    yield put(UserCreators.ssoSuccess(response.data));
  } catch (error) {
    yield put(UserCreators.ssoFailure());
  }
}

export function* requestSSOCialfo(action) {
  try {
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(ssoCialfo, authToken);
    yield put(UserCreators.ssoCialfoSuccess(response.data.url));
  } catch (error) {
    yield put(UserCreators.ssoCialfoFailure());
  }
}

export function* requestCheckSession(action) {
  try {
    const authTokenSelector = state => state.user.authToken;
    const idSelector = state => state.user.id;
    const authToken = yield select(authTokenSelector);
    const id = yield select(idSelector);
    const response = yield call(checkSession, authToken, id);
    yield put(UserCreators.checkSessionSuccess(response.data));
  } catch (error) {
    yield put(UserCreators.checkSessionFailure());
  }
}

export function* requestConfirmEmailId(action) {
  try {
    const { params } = action;
    const response = yield call(confirmaEmailId, params);

    yield put(
      UserCreators.confirmEmailIdSuccess(response.data.partner_type),
    );
  } catch (error) {
    yield put(UserCreators.confirmEmailIdFailure());
  }
}

export function* requestSendResetPasswordMail(action) {
  try {
    const { params } = action;
    const response = yield call(sendResetPasswordMail, params);
    Notification.success(response.data.message);
    yield put(UserCreators.sendResetPasswordMailSuccess());
  } catch (error) {
    yield put(UserCreators.sendResetPasswordMailFailure());
  }
}

export function* requestDemoRequest(action) {
  try {
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    yield call(requestDemo, authToken);
    Notification.success(
      i18n.t("alerts.success.demo_request_succeeded.description"),
      {
        customFields: {
          title: i18n.t(
            "alerts.success.demo_request_succeeded.title",
          ),
        },
      },
    );
    yield put(UserCreators.requestDemoSuccess());
  } catch (error) {
    yield put(UserCreators.requestDemoFailure());
  }
}

export function* requestResetPassword(action) {
  try {
    const { params, resolve } = action;
    const response = yield call(resetPassword, params);
    Notification.success(response.data.message);
    yield put(UserCreators.resetPasswordSuccess());
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(UserCreators.resetPasswordFailure());
    reject(error);
  }
}

export function* requestRegisterUser(action) {
  try {
    const { params } = action;
    const response = yield call(registerUser, params);
    yield put(UserCreators.registerUserSuccess(response.data));
  } catch (error) {
    yield put(UserCreators.registerUserFailure());
  }
}

export function* requestUpdateUser(action) {
  try {
    const { params, resolve } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);

    const userIdSelector = state => state.user.data.id;
    const id = yield select(userIdSelector);
    const response = yield call(updateUser, authToken, id, params);
    resolve && resolve();
    yield put(UserCreators.updateUserSuccess(response.data));
  } catch (error) {
    const { reject } = action;
    let errorMessages = {};

    each(error.response.data.error, (e, key) => {
      errorMessages[key] = e instanceof Array ? e : [e];
    });

    reject && reject(new SubmissionError(errorMessages));
    yield put(UserCreators.updateUserFailure());
  }
}

export function* requestUpdateUserTimezone(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);

    const userIdSelector = state => state.user.data.id;
    const id = yield select(userIdSelector);

    const response = yield call(
      updateUserTimezone,
      authToken,
      id,
      params,
    );

    yield put(UserCreators.updateUserTimezoneSuccess(response.data));
  } catch (error) {
    yield put(UserCreators.updateUserTimezoneFailure());
  }
}

export function* requestUpdatePassword(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);

    const userIdSelector = state => state.user.data.id;
    const id = yield select(userIdSelector);

    yield call(updatePassword, authToken, id, params);
    yield put(UserCreators.updatePasswordSuccess());
  } catch (error) {
    yield put(UserCreators.updatePasswordFailure());
  }
}

export function* requestAcceptPrivacyPolicy(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);

    const response = yield call(
      acceptPrivacyPolicy,
      authToken,
      params,
    );
    yield put(UserCreators.acceptPrivacyPolicySuccess(response.data));
  } catch (error) {
    yield put(UserCreators.acceptPrivacyPolicyFailure());
  }
}

export function* requestUpdateUserLocale(action) {
  try {
    const { locale } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const params = new FormData();
    params.append("user[locale]", locale);

    const response = yield call(updateUserLocale, authToken, params);
    yield put(UserCreators.updateUserLocaleSuccess(response.data));
  } catch (error) {
    yield put(UserCreators.updateUserLocaleFailure());
  }
}

export function* requestUpdateEmailSettings(action) {
  try {
    const { params, id } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      updateEmailSettings,
      authToken,
      id,
      params,
    );
    yield put(UserCreators.updateEmailSettingsSuccess(response.data));
  } catch (error) {
    yield put(UserCreators.updateEmailSettingsFailure());
  }
}

export function* requestGetZoomAccount(action) {
  try {
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);

    const response = yield call(getPartner, authToken);
    const data = response.data;
    const isZoomConnected = data.is_zoom_connected ? true : false;
    const zoomAccount = isZoomConnected ? data.zoom_email : null;
    yield put(
      UserCreators.getZoomAccountSuccess(
        isZoomConnected,
        zoomAccount,
      ),
    );
  } catch (error) {
    yield put(UserCreators.getZoomAccountFailure());
  }
}

export function* requestGetZoomAuthUrl(action) {
  try {
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);

    const response = yield call(getZoomAuthUrl, authToken);
    yield put(UserCreators.getZoomAuthUrlSuccess(response.data.url));
  } catch (error) {
    yield put(UserCreators.getZoomAuthUrlFailure());
  }
}

export function* requestUpdateZoomDetails(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);

    const response = yield call(updateZoomDetails, authToken, params);
    yield put(UserCreators.updateZoomDetailsSuccess(response.data));
  } catch (error) {
    yield put(UserCreators.updateZoomDetailsFailure());
  }
}

export function* requestSisenseUrl(action) {
  try {
    const { params, resolve } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(getSisenseUrl, authToken, params);
    yield put(
      UserCreators.getSisenseUrlSuccess(response.data.sisenseUrl),
    );
    resolve(response.data.sisenseUrl);
  } catch (error) {
    const { reject } = action;
    yield put(UserCreators.getSisenseUrlFailure());
    reject(error);
  }
}

export function* requestSisenseDashboards() {
  try {
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(getSisenseDashboards, authToken);
    yield put(
      UserCreators.getSisenseDashboardsSuccess(response.data),
    );
  } catch (error) {
    yield put(UserCreators.getSisenseDashboardsFailure());
  }
}

export function* requestMetabaseUrl(action) {
  try {
    const { params, resolve } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(getMetabaseUrl, authToken, params);
    yield put(
      UserCreators.getMetabaseUrlSuccess(response.data.report_url),
    );
    resolve(response.data.report_url);
  } catch (error) {
    const { reject } = action;
    yield put(UserCreators.getMetabaseUrlFailure());
    reject(error);
  }
}

export function* requestMetabaseDashboards() {
  try {
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(getMetabaseDashboards, authToken);
    yield put(
      UserCreators.getMetabaseDashboardsSuccess(response.data),
    );
  } catch (error) {
    yield put(UserCreators.getMetabaseDashboardsFailure());
  }
}
