/* eslint-disable camelcase */
import { call, put, takeEvery, select } from "redux-saga/effects";
import {
  getConfigurationFiles,
  getDownloadFormatMapping,
  saveDownloadFormatMapping,
  getMappedColumns,
  getLeadsParameters,
  getLeadsCategories,
  saveLeadsCategories,
  getCrmList,
  saveCrm,
  addCrm,
  getSelectedCrm,
} from "src/web-services";
import {
  ConfigurationCreators,
  ConfigurationTypes,
} from "../actions";

const authTokenSelector = state => state.user.authToken;
const partnerIdSelector = state => state.user.data.partner_id;

function* requestGetConfigurationFiles() {
  try {
    const authToken = yield select(authTokenSelector);
    const response = yield call(getConfigurationFiles, authToken);
    yield put(
      ConfigurationCreators.getConfigurationFilesSuccess(
        response.data,
      ),
    );
  } catch (error) {
    yield put(ConfigurationCreators.getConfigurationFilesFailure());
  }
}

function* requestGetDownloadFormatFileMapping() {
  try {
    const authToken = yield select(authTokenSelector);
    const response = yield call(getDownloadFormatMapping, authToken);

    yield put(
      ConfigurationCreators.getDownloadFormatFileMappingSuccess(
        response.data.configuration_mappings,
      ),
    );
  } catch (error) {
    yield put(
      ConfigurationCreators.getDownloadFormatFileMappingFailure(),
    );
  }
}

function* requestSaveDownloadFormatFileMapping(action) {
  try {
    const { mapping_data } = action;
    const authToken = yield select(authTokenSelector);
    yield call(saveDownloadFormatMapping, authToken, mapping_data);

    yield put(
      ConfigurationCreators.saveDownloadFormatFileMappingSuccess(),
    );
  } catch (error) {
    yield put(
      ConfigurationCreators.saveDownloadFormatFileMappingFailure(),
    );
  }
}

function* requestGetMappedColumns() {
  try {
    const authToken = yield select(authTokenSelector);
    const response = yield call(getMappedColumns, authToken);
    yield put(
      ConfigurationCreators.getMappedColumnsSuccess(
        response.data.attributes,
      ),
    );
  } catch (error) {
    yield put(ConfigurationCreators.getMappedColumnsFailure());
  }
}

function* requestGetLeadsParameters() {
  try {
    const authToken = yield select(authTokenSelector);
    const response = yield call(getLeadsParameters, authToken);
    yield put(
      ConfigurationCreators.getLeadsParametersSuccess(
        response.data.params_list,
      ),
    );
  } catch (error) {
    yield put(ConfigurationCreators.getLeadsParametersFailure());
  }
}

function* requestGetLeadsCategories() {
  try {
    const authToken = yield select(authTokenSelector);
    const response = yield call(getLeadsCategories, authToken);
    yield put(
      ConfigurationCreators.getLeadsCategoriesSuccess(
        response.data.lead_categories,
      ),
    );
  } catch (error) {
    yield put(ConfigurationCreators.getLeadsCategoriesFailure());
  }
}

function* requestSaveLeadsCategories(actions) {
  try {
    const { data } = actions;
    const authToken = yield select(authTokenSelector);
    const response = yield call(saveLeadsCategories, authToken, data);
    yield put(
      ConfigurationCreators.saveLeadsCategoriesSuccess(
        response.data.lead_categories,
      ),
    );
  } catch (error) {
    yield put(ConfigurationCreators.saveLeadsCategoriesFailure());
  }
}

function* requestGetCrmList() {
  try {
    const authToken = yield select(authTokenSelector);
    const partnerId = yield select(partnerIdSelector);
    const response = yield call(getCrmList, partnerId, authToken);
    yield put(
      ConfigurationCreators.getCrmListSuccess(response.data.list),
    );
  } catch (error) {
    yield put(ConfigurationCreators.getCrmListFailure());
  }
}

function* requestGetSelectedCrm() {
  try {
    const authToken = yield select(authTokenSelector);
    const partnerId = yield select(partnerIdSelector);
    const response = yield call(getSelectedCrm, partnerId, authToken);
    yield put(
      ConfigurationCreators.getSelectedCrmSuccess(response.data),
    );
  } catch (error) {
    yield put(ConfigurationCreators.getSelectedCrmFailure());
  }
}

function* requestSaveCrm(actions) {
  try {
    const { data } = actions;
    const authToken = yield select(authTokenSelector);
    const partnerId = yield select(partnerIdSelector);
    const response = yield call(saveCrm, data, partnerId, authToken);
    yield put(ConfigurationCreators.saveCrmSuccess(response));
  } catch (error) {
    yield put(ConfigurationCreators.saveCrmFailure());
  }
}

function* requestAddCrm(actions) {
  try {
    const { name } = actions;
    const authToken = yield select(authTokenSelector);
    const partnerId = yield select(partnerIdSelector);
    const response = yield call(addCrm, name, partnerId, authToken);
    yield put(ConfigurationCreators.addCrmSuccess(response));
  } catch (error) {
    yield put(ConfigurationCreators.addCrmFailure());
  }
}

export function* watchConfigurationRequests() {
  yield takeEvery(
    ConfigurationTypes.GET_CONFIGURATION_FILES_REQUEST,
    requestGetConfigurationFiles,
  );
  yield takeEvery(
    ConfigurationTypes.GET_DOWNLOAD_FORMAT_FILE_MAPPING_REQUEST,
    requestGetDownloadFormatFileMapping,
  );
  yield takeEvery(
    ConfigurationTypes.SAVE_DOWNLOAD_FORMAT_FILE_MAPPING_REQUEST,
    requestSaveDownloadFormatFileMapping,
  );
  yield takeEvery(
    ConfigurationTypes.GET_MAPPED_COLUMNS_REQUEST,
    requestGetMappedColumns,
  );
  yield takeEvery(
    ConfigurationTypes.GET_LEADS_PARAMETERS_REQUEST,
    requestGetLeadsParameters,
  );
  yield takeEvery(
    ConfigurationTypes.GET_LEADS_CATEGORIES_REQUEST,
    requestGetLeadsCategories,
  );
  yield takeEvery(
    ConfigurationTypes.SAVE_LEADS_CATEGORIES_REQUEST,
    requestSaveLeadsCategories,
  );
  yield takeEvery(
    ConfigurationTypes.GET_CRM_LIST_REQUEST,
    requestGetCrmList,
  );
  yield takeEvery(
    ConfigurationTypes.GET_SELECTED_CRM_REQUEST,
    requestGetSelectedCrm,
  );
  yield takeEvery(
    ConfigurationTypes.SAVE_CRM_REQUEST,
    requestSaveCrm,
  );
  yield takeEvery(ConfigurationTypes.ADD_CRM_REQUEST, requestAddCrm);
}
