import { API } from "../helpers";
import { SERVER_URL } from "../config";

export function getConfigurationFiles(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/configuration_files`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getDownloadFormatMapping(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/partner_mappings/configuration_mappings`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function saveDownloadFormatMapping(authToken, mappingData) {
  const data = {
    partner_mapping: {
      mapping_columns: mappingData,
    },
  };
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/partner_mappings/update_configuration_mappings`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getMappedColumns(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/partner_mappings/mapped_columns`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getLeadsParameters(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/connections/leads_params`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getLeadsCategories(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/connections/lead_categories`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function saveLeadsCategories(authToken, qualityLeadsData) {
  const data = {
    lead_category: qualityLeadsData,
  };
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/connections/lead_categories`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getCrmList(partnerId, authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/universities/${partnerId}/crm_list`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getSelectedCrm(partnerId, authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/universities/${partnerId}/selected_crm`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function saveCrm(data, partnerId, authToken) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/universities/${partnerId}/add_crm_integration`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function addCrm(name, partnerId, authToken) {
  const data = {
    crm_integration: {
      crm_name: name,
    },
  };

  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/universities/${partnerId}/add_crm_name`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function testSlateConnection(data, partnerId, authToken) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/universities/${partnerId}/test_slate_connection`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}
